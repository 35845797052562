import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['columnElement']
  static values = {
    totalColumns: Number,
    visibleColumns: { type: Number, default: 2 }
  }

  static classes = ['hide']

  initialize () {
    const columnCount = this.totalColumnsValue
    const chunkSize = this.visibleColumnsValue

    if (columnCount <= chunkSize) return

    const columns = [...Array(columnCount).keys()]
    const columnGroups = this.chunk(columns, chunkSize)
    let position = 0

    this.toggleVisibility(columnGroups[position])

    this.carouselTimer = setInterval(() => {
      position = position < columnGroups.length - 1 ? position + 1 : 0

      this.toggleVisibility(columnGroups[position])
    }, 10 * 1000)
  }

  disconnect () {
    clearInterval(this.carouselTimer)
  }

  chunk (array, size) {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      const chunk = array.slice(i, i + size)
      result.push(chunk)
    }
    return result
  }

  toggleVisibility (visibleColumns) {
    this.columnElementTargets.forEach(element => {
      const columnIndex = parseInt(element.dataset.columnIndex)
      element.classList.toggle(this.hideClass, !visibleColumns.includes(columnIndex))
    })
  }
}
